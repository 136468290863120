.ReactModal__Body--open{
    overflow: hidden;
}
.right-panel-modal-tertium-overlay{
    z-index: 1000;
}
.slide-pane__header{
    background-color: #f9f9f9!important;
    z-index: 1001;
    border: none;
    border-bottom: 1px solid #d4d4d4;
}
.slide-pane__title{
    font-weight: 600!important;
    color: #343434;
    text-align: left!important;
    font-size: 1rem!important;
    text-transform: uppercase;
}
.slide-pane__close{
    color: #343434;
    font-size: 2em;
    opacity: 1!important;
    z-index: 1001;
    margin-left: 0;
    padding-left: 1.5rem;
    display: flex;
    align-items: center;
}
.slide-pane__close:hover img{
    filter: brightness(2.5); 
}
.slide-pane__title-wrapper{
    margin-left: 0;
}
.slide-pane__content{ background-color: white; z-index: 1002; margin-top:0!important; padding: 0!important; overflow-y: auto!important; }
.slide-pane{ width: 35%!important; position: fixed; right: 0; }
@media (max-width: 1400px) {
    .slide-pane{ width: 45%!important; }
}
@media (max-width: 992px) {
    .slide-pane{
        width: 96%!important;
    }
    .slide-pane .slide-pane__title{
        font-size: 16px!important;
    }
}