#MainBlock {
  display: block;
  background-color: white;
  min-height: 200px;
  z-index: 1;
  width: calc(99vw - 2ex);
  margin: auto;
  border-radius: 5px;
  position: relative;
  box-shadow: 0px 3px 9px 1px rgba(52, 52, 52, 0.2);
}
