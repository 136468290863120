._loading_overlay_overlay{
    position: fixed!important;
    right: 0!important;
    left: 0!important;
    bottom: 0!important;
    top: 0!important;
    display: flex!important;
    align-items: center!important;
    justify-content: center!important;
    z-index: 800!important;
    transition: all .5s ease!important;
    background: linear-gradient(to bottom, rgba(168, 126, 126, 0.9), rgba(200,200,200,0.9))!important;
}