@import url('https://fonts.googleapis.com/css?family=Montserrat:100,100i,200,200i,300,300i,400,400i,500,500i,600,600i,700,700i,800,800i,900,900i');

* {
    margin: 0px; 
    padding: 0px; 
    box-sizing: border-box;
}

body, html {
    height: 100%;
    font-family: 'Montserrat', sans-serif;
}

/*---------------------------------------------*/
a {
    font-family: 'Montserrat', sans-serif;
    font-size: 14px;
    line-height: 1.7;
    color: #666666;
    margin: 0px;
    transition: all 0.4s;
    -webkit-transition: all 0.4s;
    -o-transition: all 0.4s;
    -moz-transition: all 0.4s;
}

a:focus {
    outline: none !important;
}

a:hover {
    text-decoration: none;
    color: #f15923!important;
}

/*---------------------------------------------*/
h1,h2,h3,h4,h5,h6 {
    margin: 0px;
}

p {
    font-family: 'Montserrat', sans-serif;
    font-size: 14px;
    line-height: 1.7;
    color: #666666;
    margin: 0px;
}

ul, li {
    margin: 0px;
    list-style-type: none;
}


/*---------------------------------------------*/
input {
    outline: none;
    border: none;
}

textarea {
outline: none;
border: none;
}

textarea:focus, input:focus {
border-color: transparent !important;
}

input:focus::-webkit-input-placeholder { color:transparent; }
input:focus:-moz-placeholder { color:transparent; }
input:focus::-moz-placeholder { color:transparent; }
input:focus:-ms-input-placeholder { color:transparent; }

textarea:focus::-webkit-input-placeholder { color:transparent; }
textarea:focus:-moz-placeholder { color:transparent; }
textarea:focus::-moz-placeholder { color:transparent; }
textarea:focus:-ms-input-placeholder { color:transparent; }

input::-webkit-input-placeholder { color: #999999; }
input:-moz-placeholder { color: #999999; }
input::-moz-placeholder { color: #999999; }
input:-ms-input-placeholder { color: #999999; }

textarea::-webkit-input-placeholder { color: #999999; }
textarea:-moz-placeholder { color: #999999; }
textarea::-moz-placeholder { color: #999999; }
textarea:-ms-input-placeholder { color: #999999; }

/*---------------------------------------------*/
button {
    outline: none !important;
    border: none;
    background: transparent;
}

button:hover {
    cursor: pointer;
}

iframe {
    border: none !important;
}


/*//////////////////////////////////////////////////////////////////
[ Utility ]*/
.txt1 {
font-family: 'Montserrat', sans-serif;
font-size: 13px;
line-height: 1.5;
color: #999999;
}

.txt2 {
font-family: 'Montserrat', sans-serif;
font-size: 13px;
line-height: 1.5;
color: #666666;
}


/*//////////////////////////////////////////////////////////////////
[ login ]*/

.limiter {
width: 100%;
margin: 0 auto;
}

.container-login100 {
width: 100%;  
min-height: 100vh;
display: -webkit-box;
display: -webkit-flex;
display: -moz-box;
display: -ms-flexbox;
display: flex;
flex-wrap: wrap;
justify-content: center;
align-items: center;
padding: 15px;
background: #4d4d4d;
background: -webkit-linear-gradient(-135deg, #4d4d4d, #f15923);
background: -o-linear-gradient(-135deg, #4d4d4d, #f15923);
background: -moz-linear-gradient(-135deg, #4d4d4d, #f15923);
background: linear-gradient(-135deg, #4d4d4d, #f15923);
}

.wrap-login100 {
width: 960px;
background: #fff;
border-radius: 10px;
overflow: hidden;

display: -webkit-box;
display: -webkit-flex;
display: -moz-box;
display: -ms-flexbox;
display: flex;
flex-wrap: wrap;
justify-content: space-between;
padding: 57px 130px 33px 95px;
}

/*------------------------------------------------------------------
[  ]*/
.login100-pic {
width: 316px;
}

.login100-pic img {
max-width: 100%;
}


/*------------------------------------------------------------------
[  ]*/
.login100-form {
width: 390px;
}

.login100-form-title {
font-family: 'Montserrat', sans-serif;
font-size: 24px;
color: #333333;
line-height: 1.2;
text-align: center;
font-weight: bold;
width: 100%;
display: block;
padding-bottom: 54px;
}

.title-sm {
font-size: 20px;
}

.login100-form-description {
font-family: 'Montserrat', sans-serif;
font-size: 14px;
color: #666666;
line-height: 1.2;
width: 100%;
display: block;
padding-bottom: 32px;
}

.form-description-container {
display: flex;
flex-direction: column;
gap: 1rem;
padding-bottom: 2rem;
}

.form-description {
font-family: 'Montserrat', sans-serif;
font-size: 14px;
color: #666666;
line-height: 1.2;
width: 100%;
display: block;
}

.description-light {
color: #999999;
}

.text-center {
text-align: center;
}

/*---------------------------------------------*/
.wrap-input100 {
position: relative;
width: 100%;
z-index: 1;
margin-top: 10px;
}

.input100 {
font-family: 'Montserrat', sans-serif;
font-size: 15px;
line-height: 1.5;
color: #666666;

display: block;
width: 100%;
background: #e6e6e6;
height: 50px;
border-radius: 25px;
padding: 0 30px 0 68px;
}

.input100-readonly {
color: #999999;
}


/*------------------------------------------------------------------
[ Focus ]*/
.focus-input100 {
display: block;
position: absolute;
border-radius: 25px;
bottom: 0;
left: 0;
z-index: -1;
width: 100%;
height: 100%;
box-shadow: 0px 0px 0px 0px;
color: rgba(241,89,35, 0.8);
}

.input100:focus + .focus-input100 {
-webkit-animation: anim-shadow 0.5s ease-in-out forwards;
animation: anim-shadow 0.5s ease-in-out forwards;
}

.input100.is-invalid {
    border: 1px solid #ff00009c;
}
.input100.is-invalid::placeholder{
    color:#ff00009c;
}
.invalid-feedback{
    display: block!important;
    text-align: center;
}

@-webkit-keyframes anim-shadow {
to {
    box-shadow: 0px 0px 70px 25px;
    opacity: 0;
}
}

@keyframes anim-shadow {
to {
    box-shadow: 0px 0px 70px 25px;
    opacity: 0;
}
}

.symbol-input100 {
font-size: 15px;

display: -webkit-box;
display: -webkit-flex;
display: -moz-box;
display: -ms-flexbox;
display: flex;
align-items: center;
position: absolute;
border-radius: 25px;
bottom: 0;
left: 0;
width: 100%;
height: 100%;
padding-left: 35px;
pointer-events: none;
color: #666666;

-webkit-transition: all 0.4s;
-o-transition: all 0.4s;
-moz-transition: all 0.4s;
transition: all 0.4s;
}

.input100:focus + .focus-input100 + .symbol-input100 {
color: #f15923;
padding-left: 28px;
}

/*------------------------------------------------------------------
[ Button ]*/
.container-login100-form-btn {
width: 100%;
display: -webkit-box;
display: -webkit-flex;
display: -moz-box;
display: -ms-flexbox;
display: flex;
flex-wrap: wrap;
justify-content: center;
padding-top: 20px;
}

.login100-form-btn {
font-family: 'Montserrat', sans-serif!important;
font-weight: bold;
font-size: 15px;
line-height: 1.5;
color: #fff;
text-transform: uppercase;

width: 100%;
height: 50px;
border-radius: 25px!important;
background: #f15923!important;
display: -webkit-box;
display: -webkit-flex;
display: -moz-box;
display: -ms-flexbox;
display: flex;
justify-content: center;
align-items: center;
padding: 0 25px;

-webkit-transition: all 0.4s;
-o-transition: all 0.4s;
-moz-transition: all 0.4s;
transition: all 0.4s;
outline: none!important;
box-shadow: none!important;
}

.login100-form-btn:hover {
background: #333333!important;
}



/*------------------------------------------------------------------
[ Responsive ]*/



@media (max-width: 1280px) {
    .wrap-login100 {
        padding: 57px 90px 33px 85px;
    }

    .login100-pic {
        width: 30%;
    }

    .login100-form {
        width: 70%;
    }
}

@media (max-width: 992px) {
    .wrap-login100 {
        padding: 100px 80px 33px 80px;
    }

    .login100-pic {
        display: none;
    }

    .login100-form {
        width: 100%;
    }

    .login100-pic{
        width: 100%;
    }
    .wrap-login100 > div{
        width: 100%;
    }
}

@media (max-width: 576px) {
    .login100-form-description {
        text-align: center;
    }

    .wrap-login100 {
        padding: 100px 15px 33px 15px;
    }
    
}


/*------------------------------------------------------------------
[ Alert validate ]*/

.validate-input {
position: relative;
}

.alert-validate::before {
content: attr(data-validate);
position: absolute;
max-width: 70%;
background-color: white;
border: 1px solid #c80000;
border-radius: 13px;
padding: 4px 25px 4px 10px;
top: 50%;
-webkit-transform: translateY(-50%);
-moz-transform: translateY(-50%);
-ms-transform: translateY(-50%);
-o-transform: translateY(-50%);
transform: translateY(-50%);
right: 8px;
pointer-events: none;

font-family: Poppins-Medium;
color: #c80000;
font-size: 13px;
line-height: 1.4;
text-align: left;

visibility: hidden;
opacity: 0;

-webkit-transition: opacity 0.4s;
-o-transition: opacity 0.4s;
-moz-transition: opacity 0.4s;
transition: opacity 0.4s;
}

.alert-validate::after {
content: "\f06a";
font-family: FontAwesome;
display: block;
position: absolute;
color: #c80000;
font-size: 15px;
top: 50%;
-webkit-transform: translateY(-50%);
-moz-transform: translateY(-50%);
-ms-transform: translateY(-50%);
-o-transform: translateY(-50%);
transform: translateY(-50%);
right: 13px;
}

.alert-validate:hover:before {
visibility: visible;
opacity: 1;
}

@media (max-width: 992px) {
.alert-validate::before {
    visibility: visible;
    opacity: 1;
}
}
