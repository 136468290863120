@media (max-width: 992px) {
  #mainLinks {
    background-image: linear-gradient(
      to right,
      #1a1a18,
      #20201e,
      #2c2b29,
      #33322f,
      #33322f
    );
  }
}
#mainLinks,
#mainLinks * {
  transition: all 0.5s ease;
}
#mainLinks a {
  text-decoration: none !important;
  padding-top: 0.5rem !important;
  padding-bottom: 0 !important;
}
