.btn-tertium-warning{
    background-color: #f15923;
    border-radius: 35px;
    color: white;
    font-family: 'Montserrat', sans-serif;
    font-weight: 700;
    font-size: 15px;
    text-transform: uppercase;
    padding-top: 7px;
    padding-bottom: 7px;
    padding-left: 20px;
    padding-right: 20px;
    box-shadow: none;
}
.btn-tertium-warning:hover,.btn-tertium-warning:active,.btn-tertium-warning:focus{
    background-image: linear-gradient(rgba(0,0,0,.1),rgba(0,0,0,.1));
}
.btn-tertium-warning:disabled,.btn-tertium-warning[disabled]{
    background-image: linear-gradient(rgba(150,150,150,.5),rgba(150,150,150,.5));
}