.btn-tertium-gray{
    background-color: #8a8a8a;
    border-radius: 35px;
    color: white;
    font-family: 'Montserrat', sans-serif;
    font-weight: 700;
    font-size: 15px;
    text-transform: uppercase;
    padding-top: 7px;
    padding-bottom: 7px;
    padding-left: 20px;
    padding-right: 20px;
    box-shadow: none;
}
.btn-tertium-gray:hover,.btn-tertium-gray:active,.btn-tertium-gray:focus{
    background-image: linear-gradient(rgba(0,0,0,.1),rgba(0,0,0,.1));
}
.btn-tertium-gray:disabled,.btn-tertium-gray[disabled]{
    background-image: linear-gradient(rgba(150,150,150,.5),rgba(150,150,150,.5));
}