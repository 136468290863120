body {
  margin: 0;
  padding: 0;
  background-color: #ebebeb !important;
  font-family: "Montserrat", sans-serif !important;
}
.position-absolute {
  position: absolute;
}
:focus {
  outline: none !important;
}
a {
  color: inherit !important;
}
a:hover,
a:active,
a:focus {
  text-decoration: none !important;
}
.font-openSans {
  font-family: "Open Sans", sans-serif !important;
}
label {
  font-family: "Open Sans", sans-serif !important;
  margin-bottom: 0 !important;
  font-size: 13px;
}
.form-group {
  margin-bottom: 0.5rem;
}
.form-control:focus {
  border-color: #d4d4d4 !important;
}
.cursor-pointer {
  cursor: pointer;
}
.font-weight-600 {
  font-weight: 600;
}
.font-weight-500 {
  font-weight: 500;
}
.font-weight-400 {
  font-weight: 400;
}
footer * {
  font-size: 14px;
  text-decoration: none !important;
}
.orange-on-hover:hover,
.orange-on-hover:active,
.orange-on-hover:focus {
  filter: invert(42%) sepia(100%) saturate(2641%) hue-rotate(349deg)
    brightness(104%) contrast(105%);
}
.dropdown-item.active,
.dropdown-item:active {
  background-color: transparent !important;
  color: #343434 !important;
  font-weight: bold;
}
/*Card*/
.card {
  border: none !important;
}
.card .card-header {
  position: relative;
  color: #f15923;
  text-transform: uppercase;
  background-color: transparent;
  font-weight: 500;
  border: none;
  border-bottom: 2px solid #c8c8c8;
  padding-left: 0;
  padding-right: 0;
}
.card .card-header > .small {
  color: #666666;
  text-transform: none;
  font-style: italic;
  font-family: "Open Sans", sans-serif !important;
  padding-left: 15px;
}
.card .card-body {
  padding-left: 0;
  padding-right: 0;
}

/*Tertium Comments*/
.box-comment:not(:last-child) {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

/*Tertium Card*/
.tertium-card > .card-header {
  border-bottom: none;
  box-shadow: inset 0px -1px 0px 0px #d4d4d4;
  background-color: #f9f9f9;
  color: #343434;
}

/*Swal*/
.swal2-actions .swal2-confirm {
  background-color: #ff5818 !important;
  border-radius: 35px !important;
  color: white !important;
  font-family: "Montserrat", sans-serif !important;
  font-weight: 700 !important;
  font-size: 15px !important;
  text-transform: uppercase !important;
  padding-top: 7px !important;
  padding-bottom: 7px !important;
  padding-left: 40px !important;
  padding-right: 40px !important;
  -webkit-box-shadow: 0px 10px 28px 0px rgba(163, 163, 163, 1) !important;
  -moz-box-shadow: 0px 10px 28px 0px rgba(163, 163, 163, 1) !important;
  box-shadow: 0px 10px 28px 0px rgba(163, 163, 163, 1) !important;
}
.swal2-actions .swal2-cancel {
  background-color: #ebebeb !important;
  border-radius: 35px !important;
  color: #7c7c7c !important;
  font-family: "Montserrat", sans-serif !important;
  font-weight: 700 !important;
  font-size: 15px !important;
  text-transform: uppercase !important;
  padding-top: 7px !important;
  padding-bottom: 7px !important;
  padding-left: 20px !important;
  padding-right: 20px !important;
  -webkit-box-shadow: 0px 10px 28px 0px rgba(163, 163, 163, 1) !important;
  -moz-box-shadow: 0px 10px 28px 0px rgba(163, 163, 163, 1) !important;
  box-shadow: 0px 10px 28px 0px rgba(163, 163, 163, 1) !important;
}
.swal2-progress-steps .swal2-progress-step {
  background: #ff5818 !important;
}
.swal2-progress-steps .swal2-progress-step.swal2-active-progress-step {
  background: #ff5818 !important;
}
.swal2-progress-steps .swal2-progress-step-line {
  background: #ff5818 !important;
}
.swal2-progress-steps
  .swal2-progress-step.swal2-active-progress-step
  ~ .swal2-progress-step-line {
  background: #ff581870 !important;
}
.swal2-progress-steps
  .swal2-progress-step.swal2-active-progress-step
  ~ .swal2-progress-step {
  background: #ff581870 !important;
}
.swal2-input:focus {
  box-shadow: 0 0 10px 1px rgba(163, 163, 163, 0.7) !important;
}

/*ReactTable*/
.ReactTable {
  border: none !important;
}
.ReactTable .rt-table {
  z-index: 99;
}
.ReactTable .rt-thead.-header {
  min-width: 200px !important;
  box-shadow: none !important;
  background-color: #ebebeb;
  border-radius: 4px;
  padding-top: 0px;
  padding-bottom: 0px;
  color: #7c7c7c;
  font-weight: 600;
  text-transform: uppercase;
  font-size: 14px;
}
.ReactTable .rt-thead .rt-tr {
  text-align: left !important;
}
.ReactTable .rt-thead .rt-th,
.ReactTable .rt-thead .rt-td {
  padding: 10px 10px !important;
  border-right: none !important;
}
.ReactTable .rt-thead.-filters input,
.ReactTable .rt-thead.-filters select,
.ReactTable .-pagination input,
.ReactTable .-pagination select {
  border: 1px solid #ced4da;
}
.ReactTable .rt-thead.-filters input:focus,
.ReactTable .rt-thead.-filters select:focus,
.ReactTable .-pagination input:focus,
.ReactTable .-pagination select:focus {
  box-shadow: 0 0 10px 1px rgba(163, 163, 163, 0.7) !important;
}
.ReactTable .rt-thead.-filters {
  border-bottom: none !important;
  min-width: 200px !important;
}
.ReactTable .rt-tbody {
  padding: 3px;
}
.ReactTable .rt-tbody .rt-td {
  border-right: none !important;
  font-size: 15px;
  padding-left: 10px;
  white-space: normal;
  border-bottom: 1px solid #cccccc;
}
.ReactTable .rt-tbody .rt-tr-group:last-child .rt-td {
  border-bottom: none;
}
.ReactTable .pagination-bottom {
  font-size: 14px;
  padding-top: 20px;
}
.ReactTable .-pagination {
  box-shadow: none !important;
  border: none !important;
}
.ReactTable .-pagination .-previous,
.ReactTable .-pagination .-next {
  flex: unset !important;
}
.ReactTable .-pagination .-btn {
  width: 40px !important;
  height: 40px !important;
  border-radius: 50% !important;
  background-color: transparent !important;
  color: #343434 !important;
}
.ReactTable .-pagination .-btn[disabled] {
  color: rgba(0, 0, 0, 0.6) !important;
  background: transparent !important;
}
.ReactTable.-highlight .rt-tbody .rt-tr:not(.-padRow):hover,
.ReactTable.-highlight .rt-tbody .rt-tr:not(.-padRow):active,
.ReactTable.-highlight .rt-tbody .rt-tr:not(.-padRow):focus {
  border-radius: 4px; /*box-shadow: 0 0 10px 1px rgba(163,163,163,0.7)!important;*/
  background: #f9f9f9 !important;
}
.ReactTable * {
  transition: all 0.2s ease;
}
.ReactTable .rt-noData {
  width: 100%;
  text-align: center;
  height: 50%;
  background: rgba(255, 255, 255, 1) !important;
  top: 80% !important;
  margin-top: 10px;
}
.ReactTable .option-column-5 {
  flex: unset !important;
  width: 5% !important;
  text-align: center;
  color: #b2b2b2;
  min-width: 40px;
}
.ReactTable .letra-inicial {
  padding: 0px !important;
  border-bottom: none !important;
}
.ReactTable .letra-inicial > div {
  background-color: #fcded3;
  color: #f15923;
  border-radius: calc(0.25rem - 1px);
  font-weight: 600;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 35px;
}
.ReactTable .rt-tbody {
  min-width: 200px !important;
}
.ReactTable .rt-table {
  overflow: hidden !important;
}
.ReactTable .rt-tbody .rt-tr-group {
  border-bottom: none !important;
}
.ReactTable .rt-tbody .rt-tr-group:last-child {
  border-bottom: none !important;
}
@media (max-width: 768px) {
  .ReactTable .rt-tbody .rt-tr {
    flex-direction: column;
  }
  .ReactTable .rt-thead.-filters .rt-tr {
    flex-direction: column;
  }
  .ReactTable .rt-tbody .rt-td {
    width: 100% !important;
    max-width: 100% !important;
    border-bottom: none !important;
  }
  .ReactTable .rt-tbody .rt-td:last-child {
    border-bottom: 1px solid #cccccc !important;
    margin-bottom: 20px;
  }
  .ReactTable .rt-thead.-filters .rt-th {
    width: 100% !important;
  }
  .ReactTable .letra-inicial > div {
    border-radius: calc(0.25rem - 1px) !important;
    width: 100% !important;
  }
  /*Placeholders da pesquisa*/
  .ReactTable .rt-thead.-filters .nome-placeholder::before {
    content: "Nome";
    font-size: 12px;
    color: "#7c7c7c";
    width: 20%;
    display: inline-block;
  }
  .ReactTable .rt-thead.-filters .titulo-placeholder::before {
    content: "Título";
    font-size: 12px;
    color: "#7c7c7c";
    width: 20%;
    display: inline-block;
  }
  .ReactTable .rt-thead.-filters .filial-placeholder::before {
    content: "Filial";
    font-size: 12px;
    color: "#7c7c7c";
    width: 20%;
    display: inline-block;
  }
  .ReactTable .rt-thead.-filters .empresa-placeholder::before {
    content: "Empresa";
    font-size: 12px;
    color: "#7c7c7c";
    width: 20%;
    display: inline-block;
  }
  .ReactTable .rt-thead.-filters .cnpj-placeholder::before {
    content: "CNPJ";
    font-size: 12px;
    color: "#7c7c7c";
    width: 20%;
    display: inline-block;
  }
  .ReactTable .rt-thead.-filters .filial-placeholder::before {
    content: "Filial";
    font-size: 12px;
    color: "#7c7c7c";
    width: 20%;
    display: inline-block;
  }
  .ReactTable .rt-thead.-filters .cpf-placeholder::before {
    content: "CPF";
    font-size: 12px;
    color: "#7c7c7c";
    width: 20%;
    display: inline-block;
  }
  .ReactTable .rt-thead.-filters input {
    width: 80% !important;
  }
  .ReactTable .rt-th {
    max-width: unset !important;
  }

  .plus-img {
    transform: translateY(-12px) translateX(-5px) !important;
  }
}
.ReactTable .a {
  color: #b2b2b2;
  transition: none !important;
}
.ReactTable .a:hover,
.ReactTable .a:active,
.ReactTable .a:focus {
  color: #ff5818;
}

input.form-control {
  text-transform: uppercase !important;
}
.form-control:focus {
  box-shadow: 0 0 10px 1px rgba(163, 163, 163, 0.7) !important;
}
.custom-file-label::after {
  content: "Procurar" !important;
  background-color: #ff5818 !important;
  color: white !important;
  border-color: #ff5818 !important;
}
.custom-file-label:hover::after {
  background-image: linear-gradient(
    rgba(0, 0, 0, 0.1),
    rgba(0, 0, 0, 0.1)
  ) !important;
}
.custom-file-input:focus ~ .custom-file-label {
  border-color: rgba(163, 163, 163, 0.7) !important;
  box-shadow: 0 0 10px 1px rgba(163, 163, 163, 0.7) !important;
}
.custom-file-label {
  z-index: 0 !important;
}
.table-badge {
  color: white;
  font-size: 13px;
  font-weight: 600;
  padding: 5px 15px;
  border-radius: 40px;
}

/*Line in sides*/
.line-in-sides {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.line-in-sides:before,
.line-in-sides:after {
  content: "";
  border-top: 2px solid;
  margin: 0 15px 0 0;
  flex: 1 0 15px;
  color: #c8c8c8;
}
.line-in-sides:after {
  margin: 0 0 0 15px;
}

/*React Toogle*/
.react-toggle--checked .react-toggle-track {
  background-color: #39bbd2 !important;
}
.react-toggle--checked .react-toggle-thumb {
  border-color: #39bbd2 !important;
}
.react-toggle-track {
  background-color: #343434 !important;
}
.react-toggle--focus .react-toggle-thumb {
  box-shadow: none !important;
  transform: scale(1.2);
}
.react-toggle:active:not(.react-toggle--disabled) .react-toggle-thumb {
  box-shadow: none !important;
  transform: scale(1.2);
}

.link-box-dark {
  transition: all 0.3s ease;
  background-color: #343434;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white !important;
  font-weight: 600;
  font-size: 16px;
  padding: 20px;
}
.link-box-dark:hover,
.link-box-dark:active,
.link-box-dark:focus,
.link-box-dark.active {
  background-color: #1a1a18;
  color: #f15923 !important;
}

.input-group-prepend {
  height: 34px;
}

.row-table-filters {
  color: #343434;
  font-size: 15px;
  font-weight: 600;
  box-shadow: inset 0px -2px 0px 0px #c8c8c8;
}
.row-table-filters button {
  border-bottom: 2px solid transparent;
}
.row-table-filters button.active {
  border-bottom: 2px solid #f15923;
}

/* React Date Picker */
.react-date-picker {
  padding: 0 !important;
}
.react-date-picker__wrapper {
  border: none !important;
  padding: 0.275rem 0.75rem;
}
.react-date-picker__calendar-button:hover,
.react-date-picker__calendar-button:focus,
.react-date-picker__calendar-button:active {
  background-image: linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1));
}
.react-date-picker__calendar-button {
  position: absolute;
  right: 0;
  height: 100%;
  top: 0;
  padding-left: 10px !important;
  background-color: #39bbd2 !important;
  padding-right: 10px !important;
  color: white;
}
.react-date-picker__clear-button:focus {
  display: none;
}
.react-date-picker__clear-button {
  position: absolute;
  right: 38px !important;
}
.react-date-picker__button:enabled:hover svg g,
.react-date-picker__button:enabled:focus svg g {
  stroke: #39bbd2 !important;
}
.react-calendar {
  border-radius: 4px;
  border: none !important;
  box-shadow: 0px 6px 27px 0px rgba(163, 163, 163, 1);
}
.react-calendar * {
  text-transform: uppercase;
}
.react-calendar__month-view__days__day--weekend {
  color: #f15923 !important;
}
.react-calendar__tile--active {
  background-color: #39bbd2 !important;
  border-radius: 4px;
}
.react-calendar__tile--hasActive {
  background-color: #39bbd2 !important;
  color: white;
  border-radius: 4px;
}
.react-date-picker__inputGroup__input:invalid {
  background: transparent !important;
}
.react-date-picker--disabled {
  background-color: #e9ecef !important;
}
.transparent-input {
  color: white;
  max-width: 90%;
  background-color: transparent;
  font-size: 15px;
}
.transparent-input::placeholder {
  color: white;
  font-size: 13px;
}
.custom-list div:last-child {
  border-bottom: none !important;
}
@media (max-width: 992px) {
  .overflow-on-md {
    max-height: 300px;
    overflow-y: auto;
  }
}

.progress-indicator {
  position: absolute;
  font-weight: 600;
  top: -5px;
  padding: 3px;
  width: 40px;
  border-radius: 4px;
  transform: translateX(-20px);
}
.progress-indicator::after {
  content: "";
  position: absolute;
  left: 38%;
  top: 100%;
  width: 0;
  height: 0;
  border-top: 4px solid;
  border-color: inherit;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  clear: both;
}
.embed-responsive {
  position: relative;
  display: block;
  height: 0;
  padding: 0;
  overflow: hidden;
}

legend {
  font-size: 16px !important;
  padding-left: 5px !important;
  padding-right: 5px !important;
  margin-left: 10px !important;
  width: auto !important;
}
fieldset {
  border: 1px solid #cccccc !important;
}

.gap-1 {
  gap: 10px;
}

.btn-cancel {
  background-color: #ebebeb !important;
  border-radius: 35px !important;
  color: #7c7c7c !important;
  font-family: "Montserrat", sans-serif !important;
  font-weight: 700 !important;
  font-size: 15px !important;
  text-transform: uppercase !important;
  padding-top: 9px !important;
  padding-bottom: 9px !important;
  padding-left: 20px !important;
  padding-right: 20px !important;
  -webkit-box-shadow: 0px 10px 28px 0px rgba(163, 163, 163, 1) !important;
  -moz-box-shadow: 0px 10px 28px 0px rgba(163, 163, 163, 1) !important;
  box-shadow: 0px 10px 28px 0px rgba(163, 163, 163, 1) !important;
}

.btn-confirm {
  background-color: #ff5818 !important;
  color: white !important;
  border-radius: 35px !important;
  font-family: "Montserrat", sans-serif !important;
  font-weight: 700 !important;
  font-size: 15px !important;
  text-transform: uppercase !important;
  padding-top: 9px !important;
  padding-bottom: 9px !important;
  padding-left: 40px !important;
  padding-right: 40px !important;
  -webkit-box-shadow: 0px 10px 28px 0px rgba(163, 163, 163, 1) !important;
  -moz-box-shadow: 0px 10px 28px 0px rgba(163, 163, 163, 1) !important;
  box-shadow: 0px 10px 28px 0px rgba(163, 163, 163, 1) !important;
}

.rbt .rbt-input-hint,
.rbt .rbt-highlight-text,
.rbt .dropdown-item {
  text-transform: uppercase;
}
