.btn-tertium-primary{
    background-color: #ff5818;
    border-radius: 35px;
    color: white;
    font-family: 'Montserrat', sans-serif;
    font-weight: 700;
    font-size: 15px;
    text-transform: uppercase;
    padding-top: 7px;
    padding-bottom: 7px;
    padding-left: 40px;
    padding-right: 40px;
    -webkit-box-shadow: 0px 10px 28px 0px rgba(163,163,163,1);
    -moz-box-shadow: 0px 10px 28px 0px rgba(163,163,163,1);
    box-shadow: 0px 10px 28px 0px rgba(163,163,163,1);
}
.btn-tertium-primary:hover,.btn-tertium-primary:active,.btn-tertium-primary:focus{
    background-image: linear-gradient(rgba(0,0,0,.1),rgba(0,0,0,.1));
}
.btn-tertium-primary:disabled,.btn-tertium-primary[disabled]{
    background-image: linear-gradient(rgba(150,150,150,.5),rgba(150,150,150,.5));
}