.lista-contratos > div {
  border-bottom: 1px solid #cccccc;
  padding-bottom: 10px;
  padding-top: 15px;
}
.lista-contratos > div:last-child {
  border: none !important;
}
.lista-contratos > div.success:hover,
.lista-contratos > div.success:active,
.lista-contratos > div.success:focus,
.lista-contratos > div.success.active {
  background-color: #d5f0d9;
}
.lista-contratos > div.warning:hover,
.lista-contratos > div.warning:active,
.lista-contratos > div.warning:focus,
.lista-contratos > div.warning.active {
  background-color: #f9f4ed;
}
